//import { messages as portugueseMessages } from "./pt";
import { messages as spanishMessages } from "./es";
//import { messages as englishMessages } from "./en";

const messages = {
	...spanishMessages
	//...englishMessages,
	//...portugueseMessages,
};

export { messages };
